<template>
  <div id="motor-card">
    <h4 class="font-weight-bold h5 mt-3 mb-0">{{$t('motor.karta-motoru')}}</h4>


      <motor-attributes :motor="motor"></motor-attributes>

    <div class="row">
      <div class="col">
<!--
        <div class="row">
          <div class="col">
            <h5 class="h6 m-1">{{$t('motor.aktivity-budouci')}}:</h5>
            <div class="list border-bottom w-100 pb-5">
              <div class="d-inline-block activity m-1">
                <a href="#">
                  <div class="media p-1">
                    <div class="icon flex-fill align-self-center text-success">
                      <i class="fas fa-check state"></i>
                      <img class="mr-3" src="/img/aktivita-vyroba.png" alt />
                    </div>
                    <div class="info media-body align-self-center">
                      <p class="text-truncate mb-0">{{$t('motor.aktivita')}}: {{$t('motor.aktivita.vyroba')}}</p>
                      <p class="text-truncate mb-0">{{$t('motor.datum')}}: 15.8.2018</p>
                    </div>
                  </div>
                </a>
              </div>


              <div class="d-inline-block activity m-1">
                <a href="#">
                  <div class="media p-1">
                    <div class="icon flex-fill align-self-center text-warning">
                      <i class="fas fa-check state"></i>
                      <img class="mr-3" src="/img/aktivita-prevent-udrzba.png" alt />
                    </div>
                    <div class="info media-body align-self-center">
                      <p class="text-truncate mb-0">{{$t('motor.aktivita')}}: {{$t('motor.aktivita.prevent-udrzba')}}</p>
                      <p class="text-truncate mb-0">{{$t('motor.datum')}}: 15.8.2018</p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="d-inline-block activity m-1">
                <a href="#">
                  <div class="media p-1">
                    <div class="icon flex-fill align-self-center text-danger">
                      <i class="fas fa-check state"></i>
                      <img class="mr-3" src="/img/aktivita-prevent-mereni.png" alt />
                    </div>
                    <div class="info media-body align-self-center">
                      <p class="text-truncate mb-0">{{$t('motor.aktivita')}}: {{$t('motor.aktivita.prevent-mereni')}}</p>
                      <p class="text-truncate mb-0">{{$t('motor.datum')}}: 15.8.2018</p>
                    </div>
                  </div>
                </a>
              </div>

              <div class="d-inline-block activity m-1">
                <a href="#">
                  <div class="media p-1">
                    <div class="icon flex-fill align-self-center text-warning">
                      <i class="fas fa-check state"></i>
                      <img class="mr-3" src="/img/aktivita-havarijni-servis.png" alt />
                    </div>
                    <div class="info media-body align-self-center">
                      <p class="text-truncate mb-0">{{$t('motor.aktivita')}}: {{$t('motor.aktivita.havarijni-servis')}}</p>
                      <p class="text-truncate mb-0">{{$t('motor.datum')}}: 15.8.2018</p>
                    </div>
                  </div>
                </a>
              </div>

            </div>
          </div>
        </div>
-->

        <div class="options w-100 py-2">

          <div class="d-inline-block text-center">
              <router-link :to="`/main/motors/${motor.token}`"  >
                <div class="icon mx-3">
                  <div class="active-line"></div>
                  <img src="/img/prdeska.png" alt />
                </div>
                <div class="mt-1">{{$t('motor.pristrojova-deska')}}</div>
              </router-link>
          </div>
          <div class="d-inline-block text-center">
              <router-link :to="`/main/motors/${motor.token}/book`"   >
                <div class="icon mx-3">
                  <div class="active-line"></div>
                  <img src="/img/kniha.png" alt />
                </div>
                <div class="mt-1">{{$t('motor.servisni-kniha')}}</div>
              </router-link>
          </div>


          <div class="d-inline-block text-center">
            <router-link :to="`/main/motors/${motor.token}/chart`"    >
              <div class="icon mx-3">
                <div class="active-line"></div>
                <div class="iconPreview no-attribution" style=" margin:10px;height:50px; background-size: 50px; background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2ZXJzaW9uPSIxLjEiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMTAwIDEwMCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMTAwIDEwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBvbHlnb24gcG9pbnRzPSI3Ny42OCw0Mi44MSA4NS4wNjEsMzMuMTgxIDg2Ljk2OSwzOC4wOTYgOTIuMTAxLDE5LjY2NyA3NS44NDgsMjkuMTQgODAuOTU3LDI5Ljg3NiA3NC4yOSwzOC41NzQgIj48L3BvbHlnb24+PHBvbHlnb24gcG9pbnRzPSIxNS44ODIsNTguMDAzIDEyLjUzNCw1My43MTYgMTAuMjA0LDU2Ljc0MiAxMC4yMDQsNjUuMzc4ICI+PC9wb2x5Z29uPjxwb2x5Z29uIHBvaW50cz0iMjIuMDY1LDQ5Ljk3MiAzMy41NjIsNjMuMSA1My43MDMsMzUuNzY3IDU2LjU1Miw0MC43MzQgNTkuODU1LDM1LjkzNyA1NC4yNDUsMjYuMTU1IDMzLjIxNSw1NC42OTUgMjEuODA3LDQxLjY3ICAgMTguMDczLDQ2LjUyMSAyMS40MjEsNTAuODA4ICI+PC9wb2x5Z29uPjxwb2x5Z29uIHBvaW50cz0iNjYuODk4LDQ4LjIxOCA2NC43MDYsNDQuMzk3IDYxLjQwNCw0OS4xOTUgNjYuMjc1LDU3LjY4OSA3Mi4xNyw0OS45OTggNjguNzgsNDUuNzYzICI+PC9wb2x5Z29uPjxwb2x5Z29uIHBvaW50cz0iMzYuNzY2LDgxLjg0NCA2NS45NzcsMzkuNDA3IDgwLjg1LDU3Ljk5IDc1LjczOCw1OC43NTYgOTIuMTAxLDY3Ljg2NSA4Ni43OTgsNDkuOTA0IDg0LjkzMSw1NC43MjMgNjUuNjYxLDMwLjY0NyAgIDM2LjQ5OSw3My4wMSAxMC4yMDQsMzkuMzQzIDEwLjIwNCw0Ny44MzYgIj48L3BvbHlnb24+PHBvbHlnb24gcG9pbnRzPSI4LjMwNiw5MS44NTMgOC4zMDYsMi42MzEgMi42MTEsMi42MzEgMi42MTEsOTcuNTQ4IDk3LjM4OSw5Ny41NDggOTcuMzg5LDkxLjg1MyAiPjwvcG9seWdvbj48L3N2Zz4=');"></div>
              </div>
              <div class="mt-1">{{$t('motor.management')}}</div>
            </router-link>
          </div>

          <div class="d-inline-block text-center dlazdice-odkazu">
            <router-link :to="`/main/motors/${motor.token}/notifications`"  >
              <div class="icon mx-3">
                <div class="active-line"></div>
                <img src="/img/alerts.png" alt  style="width:40px;margin-top:15px;"/>
              </div>
              <div class="mt-1">{{$t('motor.upozorneni')}}</div>
            </router-link>
            <span class="notifications-counter" v-if="notifications.length>0">{{notifications.length}}</span>
          </div>

          <div class="d-inline-block text-center">
            <router-link :to="`/main/motors/${motor.token}/options`"  >
              <div class="icon mx-3">
                <div class="active-line"></div>
                <img src="/img/Installation.png" alt style="padding:12px"/>
              </div>
              <div class="mt-1">{{$t("menu.options")}}</div>
            </router-link>
          </div>

          <div class="d-inline-block text-center">
            <router-link :to="`/main/motors/${motor.token}/qr`"    >
              <div class="icon mx-3">
                <div class="active-line"></div>
                <img src="/img/qr-code.png" alt />
              </div>
              <div class="mt-1">{{$t('motor.qr-tisk')}}</div>
            </router-link>
          </div>




        </div>
<!-------------------------------------------------------------------------------------------------------------------->


        <router-view/>
<!--------------------------------------------------------------------------------------------------->




      </div>
      <!-- <div class="options col col-auto">
        <div class="text-center m-2">
          <a href="#">
            <img src="/img/qr-code.png" alt />
            <div>{{$t('option.qr-kod-motoru-tisk')}}</div>
          </a>
        </div>

        <div class="text-center m-2">
          <a href="#">
            <img src="/img/pridat-aktivitu.png" alt />
            <div>{{$t('option.pridat-aktivitu')}}</div>
          </a>
        </div>

        <div class="text-center m-2">
          <a href="#">
            <img src="/img/timeline.png" alt />
            <div>{{$t('option.zobrazit-casovou-osu')}}</div>
          </a>
        </div>

      </div> -->
    </div>
  </div>
</template>

<script>

  //import Notifications from "./Notifications";
  import MotorAttributes from "@/views/motor/MotorAttributes";
  const moment = require('moment');
  require('moment/locale/cs');
  moment.locale('cs');
//const qs = require("qs");
const axios = require("axios");
//const MotorHelper = require('../motor-helper')
axios.defaults.withCredentials = true;
export default {
  name: "KartaMotoru",
  components: {MotorAttributes},
  data() {
    return {
      motorId: this.$route.params.id ,
      motor:[],
      tab: 1,
      akt:{},
      notifications:[]

    };
  },
  mounted() {
    //console.log("mounted Karta Motoru");
    this.loadMotor(this.$route.params.id)
    this.refreshMot()
  },
  beforeRouteUpdate (to, from, next) {
    console.log('chang ', to.params.id , from.params.id)
    if (to.params.id && from.params.id  && from.params.id != to.params.id){
      this.loadMotor(to.params.id);
    }
    next()
  },
  methods: {
    df: function (time, format) {
      if (!time) return '';
      moment.locale(this.$i18n.locale);
      var a = moment(time);
      return a.format(format ? format : "LLL")
    },
    refreshMot(){
      const that = this
      //console.log('refrmot',this.$route.name)
      if (['motor-deska', 'motor-book', 'motor-notif', 'motor-chart'].indexOf(this.$route.name)>=0) {
        this.loadMotor(this.motorId)
      }
        setTimeout(function () {
          that.refreshMot()
        }, 1000 * 30)

    },

    loadMotor(id){
      this.motorId = id
      const that = this
      //console.log('load m', this.motorId)
      this.motor={}
      axios.get(process.env.VUE_APP_API_ROOT_URL + "/motors/"+this.motorId+"/?t="+ new Date().getTime() ).then(result => {
        //console.log("status motor",result.status,  result)
        if (result.status == 200) {
          this.motor = result.data
          this.$store.commit('setMotor', result.data )
          that.$nextTick(function(){
            that.$emit('motorUpdated', this.motor)
          })

        }


        //const mh = new MotorHelper(this.motor )
        this.notifications = [] // mh.getNotifications()
        for(var n=0; n<this.motor.notifications.length; n++){
          let nf = this.motor.notifications[n]
          if (nf.active )this.notifications.push(nf)
        }



      }).catch(e =>{
        console.error('errrr load motor id=',id, e)
      })
    },




  }
};
</script>

<style lang="scss">

  .ico-aktivity{
    max-width:40px;
  }

  .notifications-counter{
    position: absolute;
    top:0;
    right:10px;
    color: white;
    background: #ce1800;
    display: block;
    width:18px;
    height:18px;
    line-height: 18px;
    border-radius: 9px;
    text-align: center;
  }
  .dlazdice-odkazu{
    position: relative;
  }

</style>
